import { graphql } from 'gatsby'
import React from 'react'
import get from 'lodash/get'

import Layout from 'components/layout'
import Meta from 'components/meta'
import ContactPage from 'organisms/ContactPage'

export default function Page({ data }) {
  const metadata = get(data, 'site.metadata')
  return (
    <Layout>
      <Meta site={metadata} />
      <ContactPage />
    </Layout>
  )
}

export const query = graphql`
  query ContactPageQuery {
    site {
      metadata: siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`
