import React from 'react'
import classNames from 'classnames'

import styles from './style.module.scss'
import { SocialButtons } from 'components/SocialButtons'

const fullWidthToColumnBreakPoint = 'lg'

export default function ContactPage() {
  return (
    <div className="bg-primary flex-grow-1 pt-ms0">
      <div className="container text-white">
        <div className="d-flex flex-wrap-reverse align-items-center">
          <iframe
            className={classNames(
              `flex-grow-1 w-0 pt-ms-1 pt-${fullWidthToColumnBreakPoint}-ms1 pb-ms1`,
              styles.flexItem
            )}
            style={{ height: 450 }}
            width="100%"
            frameBorder="0"
            src="https://www.google.com/maps/embed/v1/place?q=q=%E0%B9%80%E0%B8%88.%E0%B9%80%E0%B8%AD%E0%B8%AA.%E0%B8%AA%E0%B9%80%E0%B8%95%E0%B8%99%E0%B9%80%E0%B8%A5%E0%B8%AA%2058%2F13%2C%20%E0%B8%96%E0%B8%99%E0%B8%99%E0%B8%A3%E0%B8%B2%E0%B8%8A%E0%B8%9E%E0%B8%A4%E0%B8%81%E0%B8%A9%E0%B9%8C%2C%20%E0%B8%95%E0%B8%B3%E0%B8%9A%E0%B8%A5%E0%B8%9A%E0%B8%B2%E0%B8%87%E0%B8%9E%E0%B8%A5%E0%B8%B1%E0%B8%9A%20%E0%B8%AD%E0%B8%B3%E0%B9%80%E0%B8%A0%E0%B8%AD%E0%B8%9B%E0%B8%B2%E0%B8%81%E0%B9%80%E0%B8%81%E0%B8%A3%E0%B9%87%E0%B8%94%20%E0%B8%99%E0%B8%99%E0%B8%97%E0%B8%9A%E0%B8%B8%E0%B8%A3%E0%B8%B5%2C%2011120%2C%20thailand&key=AIzaSyDYD7CPvNuXS_4Cvxua9eNHZdhQsPu09g0&language=th"
            allowFullScreen
          ></iframe>
          <article
            className={classNames(
              `flex-grow-1 w-0 p-${fullWidthToColumnBreakPoint}-ms1 mb-ms1`,
              styles.flexItem
            )}
          >
            <h1>Contact Page</h1>
            <p>58/13 ถนนราชพฤกษ์ ตำบลบางพลับ อำเภอปากเกร็ด นนทบุรี 11120</p>
            <h2 className="mt-4 mb-2 text-white" style={{ fontSize: 20 }}>
              ติดต่อสอบถาม
            </h2>
            <p className="mb-1">คุณชุง 062-452-8919</p>
            <p>คุณยศ 062-328-8919</p>
            <SocialButtons />
          </article>
        </div>
      </div>
    </div>
  )
}
